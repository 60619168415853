import { useCallback } from 'react';
import { Link } from '@imtbl/imx-sdk';

type IframePositionKeys = 'left';

type IframePositionOptions = {
  [key in IframePositionKeys]?: string;
};

type ConfigurableIframeOptions = null | {
  className?: string;
  containerElement?: HTMLElement;
  protectAgainstGlobalStyleBleed?: boolean;
  position?: IframePositionOptions;
};


const linkIframeOptions = {
  className: 'my-link-iframe',
};
const link = new Link('https://link.sandbox.x.immutable.com', linkIframeOptions);

const TransactionAction = () => {
  const fetchTransactionHistory = useCallback(async () => {
    try {
      // Call the method to open the transaction history pop-up
      await link.history({});
    } catch (error) {
      // Handle and print out any errors that occur
      console.error('Error opening transaction history:', error);
    }
  }, []);

  return (
    
      <span className="stripe_settings" onClick={fetchTransactionHistory}>
      View Transactions in Blockchain
      </span>

  );
};

export default TransactionAction;
